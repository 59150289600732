import { api } from "@/utils/api";

export function graud(to, from, next) {
  api("query { me { id isSuperuser username } }").then(data => {
    if (data.me !== null) {
      next();
    } else {
      // console.log("[ROUTE]: Authenication failed, going to login");
      next({ name: "Login" });
    }
  });
}

export function superusergraud(to, from, next) {
  api("query { me { id isSuperuser username } }").then(data => {
    if (data.me !== null) {
      data.me.isSuperuser ? next() : next("/");
      next();
    } else {
      // console.log("[ROUTE]: Authenication failed, going to login");
      next({ name: "Login" });
    }
  });
}
