import Vue from "vue";
import Router from "vue-router";

import { graud, superusergraud } from "./guards";

Vue.use(Router);

export default new Router({
  mode: "history",
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      component: () => import("@/containers/Default"),
      redirect: { name: "Dashboard" },
      name: "Root",
      beforeEnter: graud,
      children: [
        {
          path: "/_",
          name: "Dashboard",
          component: () => import("@/views/Dashboard")
        },
        {
          path: "/challenges",
          name: "Challenges",
          component: () => import("@/views/Challenges")
        },
        {
          path: "/challenge/:id",
          name: "Challenge",
          component: () => import("@/views/Challenge")
        },
        {
          path: "/jacktrice",
          name: "JackTrice",
          component: () => import("@/views/JackTrice")
        },
        {
          path: "/rules",
          name: "Rules",
          component: () => import("@/views/Rules")
        },
        {
          path: "/profile/:id",
          name: "Profile",
          component: () => import("@/views/Profile")
        }
      ]
    },
    {
      path: "/admin",
      component: () => import("@/containers/Default"),
      name: "Admin",
      beforeEnter: superusergraud,
      children: [
        {
          path: "create/challenge",
          name: "CreateChallenge",
          component: () => import("@/views/admin/CreateChallenge")
        },
        {
          path: "challenges",
          name: "AdminChallenges",
          component: () => import("@/views/admin/AdminChallenges")
        },
        {
          path: "challenge/modify",
          name: "ModifyChallenge",
          component: () => import("@/views/admin/ModifyChallenge")
        },
        {
          path: "/submit/results",
          name: "SubmitResults",
          component: () => import("@/views/admin/SubmitResults")
        },
        {
          path: "/submit/results/:id",
          name: "SubmitResult",
          component: () => import("@/views/admin/SubmitResult")
        }
      ]
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/authentication/Login")
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/views/authentication/Register")
    },
    {
      path: "/reset/:id",
      name: "Reset",
      component: () => import("@/views/authentication/Reset")
    },
    {
      path: "*",
      redirect: { name: "Dashboard" },
      component: {
        render(c) {
          return c("router-view");
        }
      }
    }
  ]
});
