import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import BootstrapVue from "bootstrap-vue";
require("./assets/css/bootstrap.min.css");

Vue.use(BootstrapVue);
Vue.use(require('vue-moment'));

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router
}).$mount("#app");
